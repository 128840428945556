const History = ({ heading, paragraphs }) => (
  <section className="bg-near-white pv5">
    <div className="container sm:pv3 md:pv4 lg:pv5">
      <h2 className="section-heading mb4 sm:pb3">
        <span dangerouslySetInnerHTML={{ __html: heading.black }} />
        <span className="db yellow">{heading.yellow}</span>
      </h2>

      {paragraphs.map((paragraph, i) => (
        <p key={i} className="mt3 measure-wide lh-copy">
          {paragraph}
        </p>
      ))}
    </div>
  </section>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

export default History
