const Team = ({ heading, image, paragraphs }) => (
  <section className="bg-near-white pv5">
    <div className="container pv3 sm:pv4 lg:pv5">
      <h2 className="section-heading mb4 sm:pb3">
        <span dangerouslySetInnerHTML={{ __html: heading.black }} />
        <span className="db yellow">{heading.yellow}</span>
      </h2>

      <div className="team-grid">
        <Img
          fluid={image.file.childImageSharp.fluid}
          alt={image.alt}
          objPosition={image.objPosition}
          className="thumbnail b--white mb4"
        />

        <div>
          {paragraphs.map((paragraph, i) => (
            <p key={i} className="mt3 measure lh-copy">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  </section>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Img from '../components/Img'

export default Team
