const StoryPage = ({ data }) => {
  const hero = data.storyYaml.hero
  const history = data.storyYaml.history
  const why = data.storyYaml.why
  const team = data.storyYaml.team
  const contact = data.contactYaml
  const serviceList = data.allServiceListYaml.edges

  // Remove Asphalt from the list of services shown on the Contact form
  const contactServiceList = serviceList.filter(
    service => service.node.heading !== 'Asphalt Maintenance'
  )

  return (
    <Base>
      <PageMetadata page={data.site.siteMetadata.storyPage} />

      <main id="main-content" tabIndex="-1" className="mukta dark-gray">
        <Hero
          title={hero.title}
          tagline={hero.tagline}
          link={hero.link}
          image={hero.image}
        />

        <History heading={history.heading} paragraphs={history.paragraphs} />

        <Why heading={why.heading} reasons={why.reasons} />

        <Team
          heading={team.heading}
          image={team.image}
          paragraphs={team.paragraphs}
        />

        <Contact
          stamp={contact.stamp}
          heading={contact.heading}
          invitation={contact.invitation}
          services={contactServiceList}
          successMessage={contact.successMessage}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query {
    site {
      siteMetadata {
        storyPage {
          description
          title
          url
        }
      }
    }
    storyYaml {
      hero {
        title {
          white
          yellow
        }
        tagline
        link {
          href
          text
        }
        image {
          file {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          objPosition
        }
      }
      history {
        heading {
          black
          yellow
        }
        paragraphs
      }
      why {
        heading {
          black
          yellow
        }
        reasons {
          heading
          paragraph
        }
      }
      team {
        heading {
          black
          yellow
        }
        image {
          file {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          objPosition
        }
        paragraphs
      }
    }
    contactYaml {
      stamp {
        largeText
        smallText
      }
      heading
      invitation
      successMessage
    }
    allServiceListYaml {
      edges {
        node {
          heading
        }
      }
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Base from '../components/Base'
import PageMetadata from '../components/PageMetadata'
import Hero from '../components/Hero'
import History from '../sections/StoryHistory'
import Why from '../sections/StoryWhy'
import Team from '../sections/StoryTeam'
import Contact from '../sections/Contact'

export default StoryPage
