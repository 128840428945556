const Why = ({ heading, reasons }) => (
  <section className="container pv5 sm:mv3 md:mv4 lg:mv5">
    <h2 className="section-heading">
      <span dangerouslySetInnerHTML={{ __html: heading.black }} />
      <span className="db yellow">{heading.yellow}</span>
    </h2>

    <ul className="nt4 sm:nt3 lg:nt0">
      {reasons.map((reason, i) => (
        <Reason key={i} heading={reason.heading} paragraph={reason.paragraph} />
      ))}
    </ul>
  </section>
)

const Reason = ({ heading, paragraph }) => {
  let icon
  if (heading.includes('ROI')) icon = <BarChartSVG className="pa1 w-60" />
  if (heading.includes('Protection')) icon = <HourglassSVG className="pa3 w-70" />
  if (heading.includes('Sustainable')) icon = <LeafSVG className="pa3 w-80" />

  return (
    <li className="why-grid mt5">
      <span aria-hidden="true" className="icon-why mb4">
        {icon}
      </span>

      <div>
        <h3 className="section-subheading mb3 sm:pb2 ttc">{heading}</h3>
        <p className="measure lh-copy">{paragraph}</p>
      </div>
    </li>
  )
}

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import { ReactComponent as BarChartSVG } from '../svg/chart-bar.svg'
import { ReactComponent as HourglassSVG } from '../svg/hourglass.svg'
import { ReactComponent as LeafSVG } from '../svg/leaf.svg'

export default Why
